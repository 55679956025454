import React, { useEffect, useState, useRef } from 'react';
import { createClient } from '@supabase/supabase-js';
import DataTable from 'react-data-table-component';

const supabaseUrl = 'https://kwlyjhzcptawylkehuru.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt3bHlqaHpjcHRhd3lsa2VodXJ1Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczMzc3NzkzNywiZXhwIjoyMDQ5MzUzOTM3fQ.6dRzPHLrrQTwU7EbYdht3X5ohbfsEeypKQOG7NC_5Jk';
const supabase = createClient(supabaseUrl, supabaseKey);
const customStyles = {
    table: {
        style: {
            tableLayout: 'fixed', // Fix the table layout to prevent squishing
            width: '200%', // Increase the width to make the table longer
        },
    },
    header: {
        style: {
            fontSize: '13px',
            fontWeight: 'bold',
            color: '#333',
            backgroundColor: '#f1f1f1',
        },
    },
    rows: {
        style: {
            minHeight: '50px', // Override the row height
        },
    },
    cells: {
        style: {
            fontSize: '12px',
            whiteSpace: 'normal', // Prevent cell content from wrapping
            textAlign: 'center', // Ensure text is centered
        },
    },
    headCells: {
        style: {
            fontSize: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap', // Allow header text to wrap
            // textOverflow: 'ellipsis', // Handle long text gracefully
            // overflow: 'visible', // Ensure overflow is visible
        },
    },
    pagination: {
        style: {
            backgroundColor: '#f9f9f9',
            borderTop: '1px solid #ddd',
        },
    },
    context: {
        style: {
            background: '#000000',
            text: '#000000',
        },
    },
};

const TrackerPublic = () => {
    const [trackTest1, setTrackTest1] = useState([]);
    const [trackTest2, setTrackTest2] = useState([]);
    const [error, setError] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    
    // Filters for Table 1
    const [searchQueryTable1, setSearchQueryTable1] = useState('');
    const [minValueTable1, setMinValueTable1] = useState('');
    const [maxValueTable1, setMaxValueTable1] = useState('');
    const [selectedColumnTable1, setSelectedColumnTable1] = useState('');
    const [selectedGroupTable1, setSelectedGroupTable1] = useState('');

    // Filters for Table 2
    const [searchQueryTable2, setSearchQueryTable2] = useState('');
    const [minValueTable2_1, setMinValueTable2_1] = useState('');
    const [maxValueTable2_1, setMaxValueTable2_1] = useState('');
    const [selectedColumnTable2_1, setSelectedColumnTable2_1] = useState('');
    const [selectedGroupTable2, setSelectedGroupTable2] = useState('');
    const [minValueTable2_2, setMinValueTable2_2] = useState('');
    const [maxValueTable2_2, setMaxValueTable2_2] = useState('');
    const [selectedColumnTable2_2, setSelectedColumnTable2_2] = useState('');
    const [minValueTable2_3, setMinValueTable2_3] = useState('');
    const [maxValueTable2_3, setMaxValueTable2_3] = useState('');
    const [selectedColumnTable2_3, setSelectedColumnTable2_3] = useState('');
    const [minValueTable2_4, setMinValueTable2_4] = useState('');
    const [maxValueTable2_4, setMaxValueTable2_4] = useState('');
    const [selectedColumnTable2_4, setSelectedColumnTable2_4] = useState('');   
    const [minValueTable2_5, setMinValueTable2_5] = useState('');
    const [maxValueTable2_5, setMaxValueTable2_5] = useState('');
    const [selectedColumnTable2_5, setSelectedColumnTable2_5] = useState('');   
    const [solanaPrice, setSolanaPrice] = useState(null);
    const [ethPrice, setEthPrice] = useState(null);
    const [btcPrice, setBtcPrice] = useState(null);
    const [suiPrice, setSuiPrice] = useState(null);
    const [aptosPrice, setAptosPrice] = useState(null);

    const dropDuplicates = (data) => {
        const seen = new Set();
        return data.filter(item => {
            const serializedItem = JSON.stringify(item); // Serialize the object
            if (seen.has(serializedItem)) {
                return false; // Duplicate found
            }
            seen.add(serializedItem);
            return true; // Unique item
        });
    };

    // Fetch data from the 'track-test' table on component load
    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch data for Table 1
                const { data: dataTable1, error: errorTable1 } = await supabase.from('tracker-public')
                    .select('*')
                if (errorTable1) throw errorTable1;

                const uniqueDataTable1 = dropDuplicates(dataTable1 || []);
                setTrackTest1(uniqueDataTable1 || []); // For Table 1

            } catch (err) {
                setError(err);
            }

            try {
                // Fetch data for Table 2 separately
                const { data: dataTable2, error: errorTable2 } = await supabase.from('tracker-public')
                    .select('*')
                if (errorTable2) throw errorTable2;

                const uniqueDataTable2 = dropDuplicates(dataTable2 || []);
                setTrackTest2(uniqueDataTable2 || []); // For Table 2

            } catch (err) {
                setError(err);
            }
        };

        fetchData();

        const fetchPrice = async (coinId, setPrice) => {
            try {
                const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${coinId}&vs_currencies=usd`);
                const data = await response.json();
                setPrice(data[coinId].usd);
            } catch (error) {
                console.error(`Error fetching ${coinId} price:`, error);
            }
        };

        // Fetch prices for different cryptocurrencies
        fetchPrice('solana', setSolanaPrice);
        fetchPrice('ethereum', setEthPrice);
        fetchPrice('bitcoin', setBtcPrice);
        fetchPrice('sui', setSuiPrice);
        fetchPrice('aptos', setAptosPrice);
    }, []);

    // Function to copy wallet addresses to clipboard
    const copyAddresses = () => {
        const addresses = selectedRows.map(row => row.WalletAddress).join(', ');
        navigator.clipboard.writeText(addresses)
            .then(() => alert('Addresses copied to clipboard!'))
            .catch(err => console.error('Failed to copy: ', err));
    };

    const contextActions = React.useMemo(() => {
        return []; // No actions
    }, [selectedRows]);


    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('en-US', {
            month: 'short', // 'short' for abbreviated month
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false // Set to false for 24-hour format
        });
    };

    // Define columns dynamically
    const columns = trackTest1.length
    ? Object.keys(trackTest1[0]).map((key) => ({
          name: (
              <div className="column-header" title={key}>
                  {key}
              </div>
          ),
          selector: (row) => row[key],
          sortable: true,
          cell: (row) => {
            if (key === 'WalletAddress') { // Check if the column is WalletAddress
                return (
                    <a href={`https://www.birdeye.so/profile/${row.WalletAddress}?chain=solana&duration=30D`} target="_blank" rel="noopener noreferrer">
                        {row.WalletAddress.substring(0, 6)}... {/* Show first 8 characters */}
                    </a>
                );
            } 
            return row[key]; // Default rendering for other columns
        }
      }))
    : [];

    // Get unique groups from the data
    const uniqueGroups1 = [...new Set(trackTest1.map(row => row.WinGroup))];
    console.log(uniqueGroups1);
    const uniqueGroups2 = [...new Set(trackTest2.map(row => row.WinGroup))];
    console.log(uniqueGroups2);

    // Filter data for the first table (e.g., Avg X PNL > 2000)
    const filteredDataTable1 = trackTest1.filter(row => 
        Object.values(row).some(value => 
            String(value).toLowerCase().includes(searchQueryTable1.toLowerCase())
        ) && 
        (minValueTable1 === '' || row[selectedColumnTable1] >= parseFloat(minValueTable1)) &&
        (maxValueTable1 === '' || row[selectedColumnTable1] <= parseFloat(maxValueTable1)) &&
        (selectedGroupTable1 === '' || row.WinGroup === selectedGroupTable1)
    );

    // Filter data for the second table (e.g., Avg X PNL < 1000)
    const filteredDataTable2 = trackTest2.filter(row => 
        Object.values(row).some(value => 
            String(value).toLowerCase().includes(searchQueryTable2.toLowerCase())
        )&& 
        (minValueTable2_1 === '' || row[selectedColumnTable2_1] >= parseFloat(minValueTable2_1)) &&
        (maxValueTable2_1 === '' || row[selectedColumnTable2_1] <= parseFloat(maxValueTable2_1)) &&
        (minValueTable2_2 === '' || row[selectedColumnTable2_2] >= parseFloat(minValueTable2_2)) &&
        (maxValueTable2_2 === '' || row[selectedColumnTable2_2] <= parseFloat(maxValueTable2_2)) &&
        (minValueTable2_3 === '' || row[selectedColumnTable2_3] >= parseFloat(minValueTable2_3)) &&
        (maxValueTable2_3 === '' || row[selectedColumnTable2_3] <= parseFloat(maxValueTable2_3)) &&
        (minValueTable2_4 === '' || row[selectedColumnTable2_4] >= parseFloat(minValueTable2_4)) &&
        (maxValueTable2_4 === '' || row[selectedColumnTable2_4] <= parseFloat(maxValueTable2_4)) &&
        (minValueTable2_5 === '' || row[selectedColumnTable2_5] >= parseFloat(minValueTable2_5)) &&
        (maxValueTable2_5 === '' || row[selectedColumnTable2_5] <= parseFloat(maxValueTable2_5)) &&
        (selectedGroupTable2 === '' || row.WinGroup === selectedGroupTable2)
    );

    return (
        <>
        <div>
        <div name="banners">
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '3%', 
            textAlign: 'center' 
        }}>
            <a href="https://poofcoin.xyz/" target="_blank" rel="noopener noreferrer"> <img src="poof.png" alt="POOF" style={{ maxWidth: '130px', height: 'auto' }} /> </a>
        </div> */}
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '13%', 
            textAlign: 'center' 
        }}>
            <a href="https://www.ayaonsui.xyz/" target="_blank" rel="noopener noreferrer"> <img src="aya.png" alt="aya" style={{ maxWidth: '220px', height: 'auto' }} /> </a>
        </div> */}
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '22%', 
            left: '3%',
            textAlign: 'center' 
        }}>
            <a href="https://x.com/mumu_bull" target="_blank" rel="noopener noreferrer"> <img src="mumu.png" alt="mumu" style={{ maxWidth: '120px', height: 'auto' }} /> </a>
        </div> */}
        <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '15%', 
            textAlign: 'center' 
        }}>
           <a href="https://www.kernels.fun/about" target="_blank" rel="noopener noreferrer"> <img src="pepedance.gif" alt="pepe" style={{ maxWidth: '215px', height: 'auto' }} /> </a>
        </div>
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            right: '1%', 
            textAlign: 'center' 
        }}>
            <img src="yourtoken.png" alt="yourtoken" style={{ maxWidth: '250px', height: 'auto' }} /> 
        </div> */}
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '22%', 
            right: '3%', 
            textAlign: 'center' 
        }}>
            <a href="https://x.com/_basedart" target="_blank" rel="noopener noreferrer"> <img src="based.png" alt="based" style={{ maxWidth: '175px', height: 'auto' }} /> </a>
        </div> */}
        <div className='price-banner' style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '3%', 
            zIndex: 1000, // Ensure it appears above other content
            textAlign: 'center' ,
            color: 'white',
            backgroundColor: 'black',
            marginRight: '20px',
            padding: '20px',
        }}>
        <b> Market Prices </b><br></br>
        SOL: {solanaPrice ? `$${solanaPrice}` : 'Loading...'}<br></br> 
        ETH: {ethPrice ? `$${ethPrice}` : 'Loading...'}<br></br> 
        BTC: {btcPrice ? `$${btcPrice}` : 'Loading...'}<br></br> 
        SUI: {suiPrice ? `$${suiPrice}` : 'Loading...'}<br></br> 
        {/* APT: {aptosPrice ? `$${aptosPrice}` : 'Loading...'}<br></br>  */}
        </div>
        </div>
        <div style={{ marginTop: '6%' }}></div>
        <div>
        <h1>KERNELS DOT FUN</h1>
        <h2 style={{color: 'green'}}>WALLET SEARCH</h2>
        </div>
            <div>
            <h4>start filtering and search smart wallets of your style! <br></br> 
            Kernels DB is updated with top wallets from selected tokens</h4>
            {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
            
            {/* Filters for Table 2 */}
            <select 
                value={selectedGroupTable2} 
                onChange={(e) => setSelectedGroupTable2(e.target.value)} // Group selection dropdown
            >
                <option value="">Select Group</option>
                {uniqueGroups2.map((group, index) => (
                    <option key={index} value={group}>{group}</option>
                ))}
            </select>
            <input 
                type="text" 
                placeholder="Search..." 
                value={searchQueryTable2} 
                onChange={(e) => setSearchQueryTable2(e.target.value)} // Search input field
            />
 
            <br></br>
            <br></br>

            {/* First set of filters */}
            <select 
                value={selectedColumnTable2_1} 
                onChange={(e) => setSelectedColumnTable2_1(e.target.value)} // First column selection dropdown
            >
                <option value="">Select Column</option>
                {columns.map((col) => (
                    <option key={col.name} value={col.selector}>{col.name}</option>
                ))}
            </select>
            <input 
                type="number" 
                placeholder="Min Value" 
                value={minValueTable2_1} 
                onChange={(e) => setMinValueTable2_1(e.target.value)} // First min value input
            />
            <input 
                type="number" 
                placeholder="Max Value" 
                value={maxValueTable2_1} 
                onChange={(e) => setMaxValueTable2_1(e.target.value)} // First max value input
            />
            <br></br>

            {/* Second set of filters */}
            <select 
                value={selectedColumnTable2_2} 
                onChange={(e) => setSelectedColumnTable2_2(e.target.value)} // Second column selection dropdown
            >
                <option value="">Select Column</option>
                {columns.map((col) => (
                    <option key={col.name} value={col.selector}>{col.name}</option>
                ))}
            </select>
            <input 
                type="number" 
                placeholder="Min Value" 
                value={minValueTable2_2} 
                onChange={(e) => setMinValueTable2_2(e.target.value)} // Second min value input
            />
            <input 
                type="number" 
                placeholder="Max Value" 
                value={maxValueTable2_2} 
                onChange={(e) => setMaxValueTable2_2(e.target.value)} // Second max value input
            />
            <br></br>
            {/* Third set of filters */}
            <select 
                value={selectedColumnTable2_3} 
                onChange={(e) => setSelectedColumnTable2_3(e.target.value)} // Third column selection dropdown
            >
                <option value="">Select Column</option>
                {columns.map((col) => (
                    <option key={col.name} value={col.selector}>{col.name}</option>
                ))}
            </select>
            <input 
                type="number" 
                placeholder="Min Value" 
                value={minValueTable2_3} 
                onChange={(e) => setMinValueTable2_3(e.target.value)} // Third min value input
            />
            <input 
                type="number" 
                placeholder="Max Value" 
                value={maxValueTable2_3} 
                onChange={(e) => setMaxValueTable2_3(e.target.value)} // Third max value input
            />
            <br></br>
            {/* Fourth set of filters */}
            <select 
                value={selectedColumnTable2_4} 
                onChange={(e) => setSelectedColumnTable2_4(e.target.value)} // Fourth column selection dropdown
            >
                <option value="">Select Column</option>
                {columns.map((col) => (
                    <option key={col.name} value={col.selector}>{col.name}</option>
                ))}
            </select>
            <input 
                type="number" 
                placeholder="Min Value" 
                value={minValueTable2_4} 
                onChange={(e) => setMinValueTable2_4(e.target.value)} // Fourth min value input
            />
            <input 
                type="number" 
                placeholder="Max Value" 
                value={maxValueTable2_4} 
                onChange={(e) => setMaxValueTable2_4(e.target.value)} // Fourth max value input
            />
            <br></br>
               {/* Fifth set of filters */}
            <select 
                value={selectedColumnTable2_5} 
                onChange={(e) => setSelectedColumnTable2_5(e.target.value)} // Fifth column selection dropdown
            >
                <option value="">Select Column</option>
                {columns.map((col) => (
                    <option key={col.name} value={col.selector}>{col.name}</option>
                ))}
            </select>
            <input 
                type="number" 
                placeholder="Min Value" 
                value={minValueTable2_5} 
                onChange={(e) => setMinValueTable2_5(e.target.value)} // Fifth min value input
            />
            <input 
                type="number" 
                placeholder="Max Value" 
                value={maxValueTable2_5} 
                onChange={(e) => setMaxValueTable2_5(e.target.value)} // Fifth max value input
            />
            <br></br>
           <br></br>
            <button onClick={copyAddresses} disabled={selectedRows.length === 0}>Copy Address</button>
            <br />
            <br></br>


            {/* Second DataTable */}
            <DataTable
                columns={columns}
                data={filteredDataTable2} // Use filtered data for table 2
                pagination
                customStyles={customStyles}
                selectableRows 
                contextActions={contextActions}
                onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                dense
            />
        </div>
        <div style={{ display: 'none' }}>
            {error && <p style={{ color: 'red' }}>Error: {error.message}</p>}
            <h2 style={{display: 'block', color: 'green' }}>avg PNL OVER 1000X can you believe it?</h2>

            {/* Filters for Table 1 */}
            <input 
                type="text" 
                placeholder="Search..." 
                value={searchQueryTable1} 
                onChange={(e) => setSearchQueryTable1(e.target.value)} // Search input field
            />
            <br></br><br></br>
            <select 
                value={selectedGroupTable1} 
                onChange={(e) => setSelectedGroupTable1(e.target.value)} // Group selection dropdown
            >
                <option value="">Select Group</option>
                {uniqueGroups1.map((group, index) => (
                    <option key={index} value={group}>{group}</option>
                ))}
            </select>
            <select 
                value={selectedColumnTable1} 
                onChange={(e) => setSelectedColumnTable1(e.target.value)} // Column selection dropdown
            >
                <option value="">Select Column</option>
                {columns.map((col) => (
                    <option key={col.name} value={col.selector}>{col.name}</option>
                ))}
            </select>
            <input 
                type="number" 
                placeholder="Min Value" 
                value={minValueTable1} 
                onChange={(e) => setMinValueTable1(e.target.value)} // Min value input
            />
            <input 
                type="number" 
                placeholder="Max Value" 
                value={maxValueTable1} 
                onChange={(e) => setMaxValueTable1(e.target.value)} // Max value input
            />
            <button onClick={copyAddresses} disabled={selectedRows.length === 0}>Copy Address</button>
            <br />
            {/* First DataTable */}
            <br></br>
            <DataTable
                columns={columns}
                data={filteredDataTable1} // Use filtered data for table 1
                pagination
                customStyles={customStyles}
                selectableRows 
                contextActions={contextActions}
                onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                dense
            /> 
            </div> 
            </div>
        </>
    );
};

export default TrackerPublic;