import { Routes, Route, Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from 'react';
import { Analytics } from "@vercel/analytics/react"

import About from './about';

import TrackerPublic from './tracker-public';
// import TrackerKRNLS from './pages/tracker-krnls-wip';
import TrackerKRNLS from './tracker-krnls';
import TrackerLive from './tracker-live';
import Aptos from './aptos';

function App() {

  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();
  useEffect(() => {
    // Improved mobile detection logic
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice = /android|iPad|iPhone|iPod|IEMobile|Opera Mini/.test(userAgent) && !window.MSStream;
    setIsMobile(isMobileDevice);

    // Set body background color based on location
    document.body.style.backgroundColor = location.pathname === "/aptos" ? '#000000' : '#F5F5F5';
  }, [location.pathname]); // Add location.pathname as dependency

  const appStyle = {
    textAlign: 'center',
    backgroundColor: location.pathname === "/aptos" ? '#000000' : '#F5F5F5',
    padding: '0px',
  };
  const navItems = [
    { path: "/about", label: "About" },
    { path: "/tracker-public", label: "TrackerPublic" },
    { path: "/tracker-live", label: "TrackerLive" },
    // { path: "/tracker-krnls", label: "TrackerKrnls" },
    // { path: "/aptos", label: "Aptos" },
];
  return (
    <div className="App" style={appStyle}>
        {isMobile ? (
            <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f8d7da', color: '#721c24' }}>
                <p>Sorry bro! desktop only for now.</p>
            </div>
        ) : (
            <>
                {location.pathname !== "/aptos" && (
                    <nav style={{  position: 'fixed', top: '3%', zIndex: 1000, width: '100%' }}>
                        {navItems.map(item => (
                            <span key={item.path} style={{ marginRight: '10px', backgroundColor: item.path === "/tracker-live" ? 'green' : 'black', color: 'white', padding: '10px', borderRadius: '5px' , fontSize: '16px', fontWeight: 'bold', textAlign: 'center' }}>
                                <Link to={item.path} style={{ color: 'white', textDecoration: 'none' }}>{item.label}</Link>
                            </span>
                        ))}
                    </nav>
                )}
                <Routes>
                    <Route path="/" element={<TrackerPublic />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/tracker-public" element={<TrackerPublic />} />
                    <Route path="/tracker-live" element={<TrackerLive />} />
                    {/* <Route path="/tracker-krnls" element={<TrackerKRNLS />} /> */}
                    <Route path="/aptos" element={<Aptos />} />
                </Routes>
                <div> 
                    <Analytics />
                </div>
            </>
        )}
    </div>
  );
}



export default App;
