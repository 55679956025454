import React, { useEffect, useState } from 'react';
import './aptos.css';
import { tidy, mutate, arrange, desc, groupBy, summarize, n, sum, median, mean, innerJoin, min, max } from '@tidyjs/tidy';

const AptosNFTDashboard = () => {
  const [salesData, setSalesData] = useState([]);
  const [summaryData, setSummaryData] = useState([]);
  const [startTime] = useState(new Date().toLocaleString());
  const [id] = useState([]);
  const [data] = useState([]);

  useEffect(() => {
    // Prevent right-click and keyboard shortcuts
    // document.addEventListener('contextmenu', event => event.preventDefault());
    // document.onkeypress = event => event.keyCode === 123 ? false : true;
    // document.onmousedown = event => event.keyCode === 123 ? false : true;
    // document.onkeydown = event => event.keyCode === 123 ? false : true;

    const fetchNFTData = async () => {
      try {
        const x = 50;
        const url = `https://fullnode.mainnet.aptoslabs.com/v1/accounts/0x2c7bccf7b31baf770fdbcc768d9e9cb3d87805e255355df5db32ac9a669010a2/events/12?limit=${x}`;
        
        const response = await fetch(url);
        const result = await response.json();

        for (let i = 0; i < x; i++) {
          const new_id = result[i]['sequence_number'];
          if (!id.includes(new_id)) {  // Only process new transactions
            const field1 = result[i]['data']['token_id']['token_data_id']['collection'];
            const token_name = result[i]['data']['token_id']['token_data_id']['name'];
            const r = /\d+/;
            const field2 = "#" + (token_name.match(r));
            const field3 = ((result[i]['data']['price']) / (10 ** 8)).toFixed(2);
            const field4 = result[i]['data']['buyer'];
            const field5 = result[i]['data']['seller'];

            id.unshift(new_id);
            data.push([1, field1, field2, field3, field4, field5]);

            // Append to existing sales data instead of replacing
            const isEven = (id.length - 1) % 2 === 0;
            setSalesData(prevSales => [
              <tr key={new_id}>
                <td>
                  <span style={{ color: isEven ? '#63de00' : 'white' }}>
                    {`${field1.slice(0, 15)} ${field2} :: ${field3}`}
                  </span>
                </td>
              </tr>,
              ...prevSales
            ]);

            // Process summary data with all accumulated data
            const data2 = data.map(([number, collection, token_number, price, buyer, seller]) => ({
              number, collection, token_number, price, buyer, seller
            }));

            const summary1 = tidy(data2, groupBy(['collection'], [
              summarize({
                NumTrade: n('price'),
                MinPrice: min('price'),
                MedianPrice: median('price'),
                MaxPrice: max('price'),
              })
            ]));

            const summary2 = tidy(
              data2,
              groupBy(['collection', 'buyer'], [
                summarize({
                  NumTrade: n('price'),
                  MedianPrice: median('price'),
                })
              ])
            );

            const summary3 = tidy(
              summary2,
              groupBy(['collection'], [
                summarize({
                  UniqBuyers: n('price'),
                })
              ])
            );

            const summary4 = tidy(
              data2,
              groupBy(['collection', 'seller'], [
                summarize({
                  NumTrade: n('price'),
                  MedianPrice: median('price'),
                })
              ])
            );

            const summary5 = tidy(
              summary4,
              groupBy(['collection'], [
                summarize({
                  UniqSellers: n('price'),
                })
              ])
            );

            const summary0 = tidy(summary1, innerJoin(summary3, { by: ['collection'] }));
            const summary = tidy(
              summary0,
              innerJoin(summary5, { by: ['collection'] }),
              arrange([desc('NumTrade')])
            );

            // Update summary table
            const summaryContent = (
              <>
                <tr style={{ color: 'white' }}>
                  <td>Collection</td>
                  <td>&nbsp; &nbsp;</td>
                  <td>NumTrades</td>
                  <td>&nbsp; &nbsp;</td>
                  <td>MinPrice</td>
                  <td>&nbsp; &nbsp;</td>
                  <td>MedPrice</td>
                  <td>&nbsp; &nbsp;</td>
                  <td>MaxPrice</td>
                  <td>&nbsp; &nbsp;</td>
                  <td>UnqSellers</td>
                  <td>&nbsp; &nbsp;</td>
                  <td>UnqBuyers</td>
                </tr>
                {summary.map((row, index) => (
                  <tr key={index}>
                    <td style={{ fontWeight: 548, borderBottom: '1px dotted #63de00', color: 'white' }}>
                      {row.collection.slice(0, 15)}
                    </td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>&nbsp; &nbsp;</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>{row.NumTrade}</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>&nbsp; &nbsp;</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>{parseFloat(row.MinPrice).toFixed(2)}</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>&nbsp; &nbsp;</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>{row.MedianPrice.toFixed(2)}</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>&nbsp; &nbsp;</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>{parseFloat(row.MaxPrice).toFixed(2)}</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>&nbsp; &nbsp;</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>{row.UniqSellers}</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>&nbsp; &nbsp;</td>
                    <td style={{ borderBottom: '1px dotted #63de00' }}>{row.UniqBuyers}</td>
                  </tr>
                ))}
                <tr style={{ color: 'white' }}>
                  <td>Total Sales</td>
                  <td>&nbsp; &nbsp;</td>
                  <td>{id.length}</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </>
            );
            setSummaryData(summaryContent);
          }
        }
      } catch (error) {
        console.error('Error fetching NFT data:', error);
      }
    };

    // Initial fetch
    fetchNFTData();

    // Set up polling interval (7 seconds)
    const interval = setInterval(fetchNFTData, 7000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div id="aptos" style={{ display: 'block', marginTop: '70px' , fontWeight: 548}}>
      <div className="txtcenter" id="info" style={{ marginBottom: '40px'}}>
        <p>Data since you got here (max num record 99,999)</p>
        <p>Arrival time: {startTime}</p>
      </div>

      <div className="row">
        <div className="column1">
          <div className="plus1">
            <table id="table_feed_head" className="table_feed_head">
              <tbody>
                <tr>
                  <td>Live Topaz Sales Feed</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="plus1">
            <table id="table_feed">
              <tbody>
                {salesData}
              </tbody>
            </table>
          </div>
        </div>

        <div className="column2">
          <div className="plus3">
            <table id="table_summary" className="table_summary">
              <tbody>
                {summaryData}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AptosNFTDashboard;

