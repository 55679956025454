import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { createClient } from '@supabase/supabase-js';
import Tidy from '@tidyjs/tidy';
const supabaseUrl = 'https://kwlyjhzcptawylkehuru.supabase.co';
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt3bHlqaHpjcHRhd3lsa2VodXJ1Iiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTczMzc3NzkzNywiZXhwIjoyMDQ5MzUzOTM3fQ.6dRzPHLrrQTwU7EbYdht3X5ohbfsEeypKQOG7NC_5Jk';
const supabase = createClient(supabaseUrl, supabaseKey);
const customStyles = {
    table: {
        style: {
            tableLayout: 'fixed', // Fix the table layout to prevent squishing
            width: '200%', // Increase the width to make the table longer
        },
    },
    header: {
        style: {
            fontSize: '13px',
            fontWeight: 'bold',
            color: '#333',
            backgroundColor: '#f1f1f1',
        },
    },
    rows: {
        style: {
            minHeight: '50px', // Override the row height
        },
    },
    cells: {
        style: {
            fontSize: '12px',
            whiteSpace: 'normal', // Prevent cell content from wrapping
            textAlign: 'center', // Ensure text is centered
        },
    },
    headCells: {
        style: {
            fontSize: '12px',
            fontWeight: 'bold',
            whiteSpace: 'nowrap', // Allow header text to wrap
            // textOverflow: 'ellipsis', // Handle long text gracefully
            // overflow: 'visible', // Ensure overflow is visible
        },
    },
    pagination: {
        style: {
            backgroundColor: '#f9f9f9',
            borderTop: '1px solid #ddd',
        },
    },
    context: {
        style: {
            background: '#000000',
            text: '#000000',
        },
    },
};

const TrackerLive = () => {
    const [password, setPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [solanaPrice, setSolanaPrice] = useState(null);
    const [ethPrice, setEthPrice] = useState(null);
    const [btcPrice, setBtcPrice] = useState(null);
    const [suiPrice, setSuiPrice] = useState(null);
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (password === 'yoyo') {
            setIsAuthenticated(true);
        } else {
            alert('Incorrect password');
        }
    };
    const [transactions, setTransactions] = useState([]);
    const [error, setError] = useState(null);
    const [walletTest, setWalletTest] = useState([]);
    const [countdown, setCountdown] = useState(420); // Initialize countdown to 600 seconds (10 minutes)
    const [randomData, setData] = useState([]);
    const [hoveredWalletData, setHoveredWalletData] = useState(null); // State to manage hovered wallet data
    const [isDrawn, setIsDrawn] = useState(false); // New state to manage button disabled state
    const [isFetching, setIsFetching] = useState(false); // New state to manage button disabled state
    
    useEffect(() => {
        const fetchPrice = async (coinId, setPrice) => {
            try {
                const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${coinId}&vs_currencies=usd`);
                const data = await response.json();
                setPrice(data[coinId].usd);
            } catch (error) {
                console.error(`Error fetching ${coinId} price:`, error);
            }
        };

        // Fetch prices for different cryptocurrencies
        fetchPrice('solana', setSolanaPrice);
        fetchPrice('ethereum', setEthPrice);
        fetchPrice('bitcoin', setBtcPrice);
        fetchPrice('sui', setSuiPrice);
    }, []);

    const dropDuplicates = (data) => {
        const seen = new Set();
        return data.filter(item => {
            const serializedItem = JSON.stringify(item); // Serialize the object
            if (seen.has(serializedItem)) {
                return false; // Duplicate found
            }
            seen.add(serializedItem);
            return true; // Unique item
        });
    };

    const fetchData = async () => {
        try {
            // Fetch data for Table 1
            const { data: dataTable1, error: errorTable1 } = await supabase.from('tracker-public')
                .select('*');
            if (errorTable1) throw errorTable1;

            const uniqueDataTable1 = dropDuplicates(dataTable1 || []);

            // Filter out entries where Avg PNL X is greater than 1 and Win Rate is greater than 0.8
            const filteredData = uniqueDataTable1.filter(item => item['Avg X PNL'] > 1 && item['winScore'] > 0.7 && item['Trades/Day'] > 20);
            // Sort by "Last Update"
            const sortedData = filteredData.sort((a, b) => new Date(b['Last Update']) - new Date(a['Last Update'])).slice(0, 500); // Get top 1000 entries

            // Get random 10 wallets
            const randomData = sortedData.sort(() => 0.5 - Math.random()).slice(0, 10); 
            console.log(randomData);
            const wallets = randomData.map(item => item.WalletAddress).sort(); // Sort wallets in ascending order
            console.log(wallets);
            setWalletTest(wallets || []); // For Table 1
            setData(randomData);

        } catch (err) {
            setError(err);
        }
    };

    const fetchWalletTransactions = async (walletAddresses) => {
        const options = {
            method: 'GET',
            headers: {
                accept: 'application/json',
                'x-chain': 'solana',
                'X-API-KEY': '3cf38c209cad40649673b3ad3223d1a6'
            }
        };

        const existingTransactionIds = new Set(transactions.map(tx => tx.id)); // Create a set of existing transaction IDs

        try {
            // Iterate over each wallet address
            for (const walletAddress of walletAddresses) {
                const response = await fetch(`https://public-api.birdeye.so/v1/wallet/tx_list?wallet=${walletAddress}&limit=10`, options);
                const data = await response.json();
                console.log('Fetched Transactions for:', walletAddress, data); // Log fetched data

                // Access the transactions from the correct path
                if (data.success && Array.isArray(data.data.solana)) {
                    const newTransactions = data.data.solana.filter(tx => !existingTransactionIds.has(tx.id)); // Filter out duplicates
                    setTransactions(prevTransactions => [...prevTransactions, ...newTransactions]); // Update the state with the new unique transactions
                } else {
                    console.error('Unexpected data structure:', data);
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    const processTransactions = (data, wallets) => {
        const ledger = [];

        wallets.forEach(wallet => {
            data.forEach(tx => {
                // Check if the transaction belongs to the current wallet
                if (tx.from === wallet || tx.to === wallet) { // Ensure the transaction is associated with the wallet
                    const txTime = tx.blockTime;

                    // Initialize variables to track SOL and other token balance changes
                    let solBalanceChange = 0;
                    let tokenSymbol = null;
                    let tokenBalanceChange = null;
                    let tokenName = null;
                    let tokenAddress = null;

                    tx.balanceChange.forEach(balanceChange => {
                        const symbol = balanceChange.symbol || balanceChange.address;
                        const name = balanceChange.name || balanceChange.address;

                        const amount = balanceChange.amount / (10 ** balanceChange.decimals); // Adjust for decimals

                        if (symbol === "SOL") {
                            tokenSymbol = "SOL";
                            tokenBalanceChange = 0;
                            tokenName = "SOLANA";
                            tokenAddress = "So11111111111111111111111111111111111111112";
                            solBalanceChange += amount;
                        } else {
                            tokenSymbol = symbol;
                            tokenBalanceChange = amount;
                            tokenName = name;
                            tokenAddress = balanceChange.address || balanceChange.address;
                        }
                    });

                    // Determine trade type based on SOL Change
                    const tradeType = solBalanceChange < 0 ? "Buy" : "Sell"; // "buy" if SOL Change is negative, "sell" if positive

                    // Filter out entries based on the new conditions
                    if ((solBalanceChange < 0 && tokenBalanceChange <= 0) || (solBalanceChange > 0 && tokenBalanceChange >= 0)) {
                        return; // Skip this entry
                    }

                    // If there's an associated token, add that entry
                    if (tokenSymbol !== "SOL") {
                        ledger.push({
                            "Token Symbol": tokenSymbol,
                            "Token Name": tokenName,
                            "SOL Change": solBalanceChange,
                            "Token Change": tokenBalanceChange,
                            "Transaction Time": txTime,
                            "Token Address": tokenAddress,
                            "Address": wallet,
                            "Trade": tradeType // Add the new Trade column
                        });
                    }
                }
            });
        });

        console.log('Processed Ledger:', ledger); // Log processed ledger

        // Sort the ledger by Transaction Time in descending order
        const uniqueLedger = Array.from(new Map(ledger.map(item => [item["Transaction Time"] + item["Address"], item])).values());
        uniqueLedger.sort((a, b) => new Date(b["Transaction Time"]) - new Date(a["Transaction Time"]));

        return uniqueLedger; // Ensure ledger is returned
    };

    // Example usage
    const ledger = processTransactions(transactions, walletTest);
    const columns = ledger.length
    ? Object.keys(ledger[0]).map((key) => ({
          name: (
              <div className="column-header" title={key}>
                  {key}
              </div>
          ),
          selector: (row) => row[key],
          sortable: true,
      }))
    : [];

    return (
        <>
        {/* {!isAuthenticated ? (
            <form onSubmit={handleSubmit}>
                <br></br>       <div style={{ marginTop: '5%' }}></div>         <br></br>
                <input 
                    type="password" 
                    value={password} 
                    onChange={handlePasswordChange} 
                    placeholder="Enter password" 
                />
                <br></br>
                <button type="submit">Submit</button>
            </form>
        ) : ( */}
        <>
        <div name="banners">
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '3%', 
            textAlign: 'center' 
        }}>
            <a href="https://poofcoin.xyz/" target="_blank" rel="noopener noreferrer"> <img src="poof.png" alt="POOF" style={{ maxWidth: '130px', height: 'auto' }} /> </a>
        </div> */}
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '13%', 
            textAlign: 'center' 
        }}>
            <a href="https://www.ayaonsui.xyz/" target="_blank" rel="noopener noreferrer"> <img src="aya.png" alt="aya" style={{ maxWidth: '220px', height: 'auto' }} /> </a>
        </div> */}
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '22%', 
            left: '3%',
            textAlign: 'center' 
        }}>
            <a href="https://x.com/mumu_bull" target="_blank" rel="noopener noreferrer"> <img src="mumu.png" alt="mumu" style={{ maxWidth: '120px', height: 'auto' }} /> </a>
        </div> */}
        <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '15%', 
            textAlign: 'center' 
        }}>
           <a href="https://www.kernels.fun/about" target="_blank" rel="noopener noreferrer"> <img src="pepedance.gif" alt="pepe" style={{ maxWidth: '215px', height: 'auto' }} /> </a>
        </div>
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '3%', 
            right: '1%', 
            textAlign: 'center' 
        }}>
            <img src="yourtoken.png" alt="yourtoken" style={{ maxWidth: '250px', height: 'auto' }} /> 
        </div> */}
        {/* <div className="ad-banner" style={{ 
            position: 'absolute', 
            top: '22%', 
            right: '3%', 
            textAlign: 'center' 
        }}>
            <a href="https://x.com/_basedart" target="_blank" rel="noopener noreferrer"> <img src="based.png" alt="based" style={{ maxWidth: '175px', height: 'auto' }} /> </a>
        </div> */}
        <div className='price-banner' style={{ 
            position: 'absolute', 
            top: '3%', 
            left: '3%', 
            zIndex: 1000, // Ensure it appears above other content
            textAlign: 'center' ,
            color: 'white',
            backgroundColor: 'black',
            marginRight: '20px',
            padding: '20px',
        }}>
        <b> Market Prices </b><br></br>
        SOL: {solanaPrice ? `$${solanaPrice}` : 'Loading...'}<br></br> 
        ETH: {ethPrice ? `$${ethPrice}` : 'Loading...'}<br></br> 
        BTC: {btcPrice ? `$${btcPrice}` : 'Loading...'}<br></br> 
        SUI: {suiPrice ? `$${suiPrice}` : 'Loading...'}<br></br> 
        </div>
        </div>
        <div style={{ marginTop: '6%' }}></div>
        <div>
            <h1>KERNELS DOT FUN</h1>
            <h1 style={{ color: 'deeppink' }}>LIVE</h1>
            <h4>what are they trading? <br></br>
            click to find out 😜</h4>
            <div>
                <button onClick={async () => {
                    setIsDrawn(true); // Disable the button when clicked
                    await fetchData(); // Fetch random wallets and transactions
                }} disabled={isDrawn}>
                    Step 1. Draw 7 Random Smart Wallets from DB
                </button>
                <button onClick={async () => {
                    setIsFetching(true); // Disable the button when clicked
                    // Wait for walletTest to update before fetching transactions
                    await new Promise(resolve => setTimeout(resolve, 100)); // Small delay to ensure state update
                    fetchWalletTransactions(walletTest); // Now fetch transactions with the updated walletTest
                    const interval = setInterval(() => {
                        fetchWalletTransactions(walletTest); // Fetch all wallet transactions together
                    }, 20000); // Every 20 seconds
                    // Countdown logic
                    const countdownInterval = setInterval(() => {
                        setCountdown(prev => {
                            if (prev <= 1) {
                                clearInterval(countdownInterval); // Clear interval when countdown reaches 0
                                return 0;
                            }
                            return prev - 1; // Decrease countdown
                        });
                    }, 3000); // Update countdown every second
                    // Stop fetching after 10 minutes
                    setTimeout(() => {
                        clearInterval(interval);
                        clearInterval(countdownInterval); // Clear countdown interval
                    }, 420000); // 7 minutes
                }} disabled={isFetching}>
                    Step 2. Start Fetching Transactions for 7 Minutes
                </button> <br></br>
                <button onClick={async () => {
                    window.location.reload(); // Refresh the page
                }}>
                    Refresh Wallets
                </button>
            </div>
            <div>
                <h4>Wallets Selected</h4>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', gap: '10px', width: '100%', maxWidth: '1200px' }}>
                    {walletTest.map((wallet, index) => {
                        const walletData = randomData.find(item => item.WalletAddress === wallet); // Find corresponding data
                        return (
                            <div key={index} 
                                 style={{ padding: '3px', border: '1px solid #ddd', borderRadius: '5px', backgroundColor: '#f9f9f9', textAlign: 'center', cursor: 'pointer' }} 
                                 onMouseEnter={() => setHoveredWalletData(walletData)} // Set hovered data on mouse enter
                                 onMouseLeave={() => setHoveredWalletData(null)} // Clear hovered data on mouse leave
                                 title={wallet}>
                                <a href={`https://www.birdeye.so/profile/${wallet}?chain=solana&duration=30D`} target="_blank" rel="noopener noreferrer">
                                    {wallet.substring(0, 6)}... {/* Show first 6 characters */}
                                </a>
                            </div>
                        );
                    })}
                </div>
                </div>
                {/* Hover box for displaying wallet data */}
                {hoveredWalletData && (
                    <div style={{
                        position: 'absolute',
                        backgroundColor: '#fff',
                        border: '1px solid #ddd',
                        borderRadius: '5px',
                        padding: '10px',
                        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
                        zIndex: 1000,
                        top: '60%', // Adjust position as needed
                        left: '50%', // Adjust position as needed
                        transform: 'translate(-50%, -50%)', // Center the box
                    }}>
                        <h4 style={{ margin: '0' }}>Wallet Data From DB</h4>
                        <p style={{ margin: '0' }}>
                            {hoveredWalletData.WalletAddress}<br />
                            Win Group: {hoveredWalletData['WinGroup']}<br />
                            Avg PNL X: {hoveredWalletData['Avg X PNL']}<br />
                            Win Score: {hoveredWalletData['winScore']}<br />
                            Trades/Day: {hoveredWalletData['Trades/Day']}<br />
                            Avg SOL IN: {hoveredWalletData['Avg SOL IN']}<br />
                            Avg SOL OUT: {hoveredWalletData['Avg SOL Out']}<br />
                        </p>
                    </div>
                )}
            </div>

        </div><br></br>
        <div> Transactions refreshing: {transactions.length} </div> 
        <div> Countdown: {Math.floor(countdown / 60)}:{(countdown % 60).toString().padStart(2, '0')} </div> 
         <br></br>
        {/* <div>
            <DataTable
                columns={columns}  
                data={ledger} // Ensure this is the processed ledger
                pagination
                customStyles={customStyles}
                selectableRows 
                dense
            />
        </div> */}
        <div> {ledger.length > 0 ? (
            <table className="styled-table" width="100%">
                <thead>
                    <tr>
                        <th>Address</th>
                        <th>Token Symbol</th>
                        <th>Token Name</th>
                        <th>Trade</th>
                        <th>SOL Change</th>
                        <th>Token Change</th>
                        <th>Transaction Time</th>
                        <th>Time Elapsed</th>
                    </tr>
                </thead>
                <tbody>
                    {ledger.map(entry => (
                        <tr key={`${entry["Transaction Time"]}-${entry["Address"]}`}>
                            <td>
                                <a href={`https://www.birdeye.so/profile/${entry["Address"]}?chain=solana&duration=30D`} target="_blank" rel="noopener noreferrer">
                                    {entry["Address"].substring(0, 6)}... {/* Show first 8 characters */}
                                </a>
                            </td>
                            <td>
                                {entry["Token Symbol"].length > 10 ? (
                                    <a href={`https://pump.fun/coin/${entry["Token Address"]}`} target="_blank" rel="noopener noreferrer">
                                        {entry["Token Symbol"]}
                                    </a>
                                ) : (
                                    <a href={`https://dexscreener.com/solana/${entry["Token Address"]}`} target="_blank" rel="noopener noreferrer">
                                        {entry["Token Symbol"]}
                                    </a>
                                )}
                            </td>
                            <td>{entry["Token Name"].length >= 40 ? entry["Token Name"].substring(0, 5) : entry["Token Name"]}</td>
                            <td style={{ color: entry["Trade"] === "Buy" ? 'green' : 'red' }}>
                                {entry["Trade"]}
                            </td>
                            <td style={{ color: entry["SOL Change"] > 0 ? 'red' : 'green' }}>
                                {entry["SOL Change"].toFixed(2)}</td>
                            <td style={{ color: entry["Token Change"] < 0 ? 'red' : 'green' }}>
                                {entry["Token Change"].toFixed(0)}
                            </td>
                            <td>{new Date(entry["Transaction Time"]).toLocaleString()}</td>
                            <td>
                                {(() => {
                                    const transactionTime = new Date(entry["Transaction Time"]);
                                    const now = new Date();
                                    const diffInSeconds = Math.floor((now - transactionTime) / 1000);
                                    
                                    if (diffInSeconds < 60) {
                                        return `${diffInSeconds}s ago`;
                                    } else {
                                        const diffInMinutes = Math.floor(diffInSeconds / 60);
                                        return `${diffInMinutes}m ago`;
                                    }
                                })()}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        ) : 'Have Fun!'} 
        </div>
        </>

        </>
    );
}

export default TrackerLive;